import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  list: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/asset/hold`, {
      data,
    });
  },
  consignment: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/asset/consignment`, {
      data,
    });
  },
  detail: async (id: number) => {
    return await http.get(`${getApiUrl()}/api/asset/detail/${id}`);
  },
  receive: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/asset/receive`, {
      data,
    });
  },
  getToken: async (id: number) => {
    return await http.get(`${getApiUrl()}/api/asset/token/${id}`);
  },
  getTokens: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/asset/tokens`, {
      data,
    });
  },
  onSale: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/asset/sale`, {
      data,
    });
  },
  offSale: async (id: number) => {
    return await http.delete(`${getApiUrl()}/api/asset/sale/${id}`);
  },
  follow: async (id: number) => {
    return await http.post(`${getApiUrl()}/api/asset/follow/${id}`);
  },
};

export default {
  ...actions,
};
